import { Grid, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import theme from 'theme/theme';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  container: {
    padding: '90px 0 60px',
    backgroundColor: theme.palette.green,
  },
  imageContainer: {
    backgroundColor: theme.palette.green,
    height: '90vh',
    maxHeight: 700,
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    backgroundSize: 'cover',
    margin: '25px 0 40px',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  textGroup: {
    marginBottom: '30px',
  },
  titleText: {},
  subTitleText: {
    fontSize: '72px',
    width: '70%',
    maxWidth: '750px',
    margin: '30px auto',
    textAlign: 'center',
    lineHeight: '58px',
  },
  leftAlign: {
    textAlign: 'left',
    margin: '30px 0',
  },
  icon: {
    width: 'auto',
    // width: '65px',
    // height: '65px',
    height: '50px',
    margin: '25px 30px',
  },
  aboutTitle: {
    lineHeight: '.8',
    margin: '6rem 0 7rem',
  },
  description: {
    display: 'block',
    marginBottom: '5em',
    '& img': {
      border: '2px solid #000000',
      margin: '50px 0',
      width: '100%',
    },
  },
  linkButtons: {
    minWidth: 'fit-content',
    padding: '5px 15px',
    margin: '0 auto 10px',
    display: 'flex',
    color: theme.palette.black,
    justifyContent: 'center',
    border: `${theme.palette.black} 1px solid`,
    maxWidth: '300px',
    '&.yellow': {
      backgroundColor: theme.palette.yellow,
      border: 'none',
    },
    '&:hover': {
      textDecoration: 'none',
    },
  },
  link: {
    color: theme.palette.black,
    textDecoration: 'underline',
  },
  partnershipSection: {
    backgroundColor: 'white',
    padding: '30px 0',
  },
  partnershipItem: {
    display: 'flex',
    '&.title': {
      justifyContent: 'center',
      paddingBottom: '25px',
    },
    '&.icons': {
      justifyContent: 'center',
      flexFlow: 'row wrap',
      alignItems: 'center',
      width: '90%',
      margin: '0 auto',
    },
  },
  featuredSection: {
    padding: '30px 0',
  },
  featuredItem: {
    display: 'flex',
    '&.title': {
      justifyContent: 'center',
      paddingBottom: '35px',
    },
    '&.icons': {
      justifyContent: 'space-evenly',
    },
  },
  serveSection: {
    margin: '75px 0 25px',
  },
  serveText: {
    fontSize: '125px',
    lineHeight: '100px',
  },
  serveTextWhite: {
    color: 'white',
    fontSize: '85px',
    lineHeight: '75px',
    width: '250px',
    [theme.breakpoints.up('sm')]: {
      width: '450px',
    },
  },
  subServeSection: {
    marginTop: '40px',
    marginBottom: '100px',
  },
  bold: {
    fontWeight: 'bold',
  },
  storiesSection: {
    display: 'flex',
    flexFlow: 'column',
    [theme.breakpoints.up('md')]: {
      flexFlow: 'row',
    },
  },
  stories: {
    marginBottom: '40px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '0px',
    },
  },
  storiesImage: {
    objectPosition: 'center',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  storiesLink: {
    marginTop: '0px',
    [theme.breakpoints.up('md')]: {
      marginTop: '110px',
    },
  },
  spacer: {
    width: '0px',
    [theme.breakpoints.up('md')]: {
      width: '75px',
    },
  },
}));

const Index = () => {
  const classes = useStyles();
  return (
    <>
      <Grid
        container
        className={classes.container}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={10} md={8} justify="center">
          <Grid item xs={12} className={classes.textGroup}>
            <Typography variant="h1" className={classes.titleText}>
              Open Wallets Can Reopen Doors
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.imageContainer}>
          <img
            className={classes.image}
            src="/images/Desktop_Hero_Image.jpg"
            alt=""
          />
        </Grid>
        <Grid item xs={12}>
          <Link className={clsx(classes.linkButtons, 'yellow')} href="/donate">
            Donate
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Link className={classes.linkButtons} href="/nominate">
            Nominate a Business
          </Link>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h1" className={classes.subTitleText}>
            100% of Donations Go Directly to the Small Businesses of Your Choice
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.partnershipSection}>
          <div className={clsx(classes.partnershipItem, 'title')}>
            <Typography>In partnership with</Typography>
          </div>
          <div className={clsx(classes.partnershipItem, 'icons')}>
            <img
              className={classes.icon}
              alt="graphite-logo"
              src="/images/logos/approved-sponsors/Graphite-Logo-For-Web.png"
            />
            <img
              className={classes.icon}
              style={{ height: '125px' }}
              alt="covid-foundation-logo"
              src="/images/logos/approved-sponsors/CovidFoundationLogo-wordmarkonly_black.png"
            />
            <img
              className={classes.icon}
              alt="ona-logo"
              src="/images/logos/approved-sponsors/ONA-logo-black.png"
            />
            <img
              className={classes.icon}
              style={{ height: '75px' }}
              alt="lightmatter-logo"
              src="/images/logos/approved-sponsors/LM-Vertical-Lockup.png"
            />
            <img
              className={classes.icon}
              alt="oliver-libby-logo"
              src="/images/logos/approved-sponsors/Oliver-Libby.png"
            />
            <img
              className={classes.icon}
              alt="spark-logo"
              src="/images/logos/approved-sponsors/spark-logo2.png"
            />
            <img
              className={classes.icon}
              alt="missy-capone-logo"
              src="/images/logos/approved-sponsors/Missy-Capone.png"
            />
            <img
              className={classes.icon}
              style={{ height: '60px' }}
              alt="dla-piper-logo"
              src="/images/logos/approved-sponsors/DLA_Piper_2.png"
            />
            <img
              className={classes.icon}
              alt="ian-pollack-logo"
              src="/images/logos/approved-sponsors/Ian-Pollack.png"
            />
            <img
              className={classes.icon}
              style={{ height: '75px' }}
              alt="dla-piper-logo"
              src="/images/logos/approved-sponsors/20th-Logo-Black.png"
            />
          </div>
        </Grid>
        <Grid item xs={10} md={8} justify="center">
          <Grid item xs={4} className={classes.serveSection}>
            <Typography variant="h1" className={classes.serveText}>
              They Serve You.
            </Typography>
            <Typography variant="h1" className={classes.serveTextWhite}>
              Here's Your Chance
            </Typography>
            <Typography variant="h1" className={classes.serveText}>
              To Serve Them.
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.subServeSection}>
            <Typography variant="h6">
              Back to normal? Not even close. Small businesses continue to
              struggle during this critical period of "returning to normal" as
              the world reopens.
            </Typography>
            <br />
            <Typography variant="h6">
              <b>But there is no normal without these storefronts.</b>
            </Typography>
            <br />
            <Typography variant="h6">
              Your 24-hour pizza spot, cafe, bodega, karaoke bar, laundromat,
              nail salon, long-standing mom and pop shop — these businesses keep
              your neighborhood alive and lively. They make the city feel like
              home, even if you're a tourist.
            </Typography>
            <br />
            <Typography variant="h6">
              And <b>without them</b>, New York couldn't be the City that Never
              Sleeps.
            </Typography>
            <br />
            <Typography variant="h6">
              Many have closed indefinitely because of the pandemic, with
              thousands more currently indebted to landlords and bill collectors
              alike. Now is our chance to save them, <b>both open and closed</b>
              , by supporting them financially as they rebuild.
            </Typography>
            <br />
            <Typography variant="h6">
              If these places disappear, so do your NYC way of life,
              communities, and culture.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h1"
              className={clsx(classes.subTitleText, classes.leftAlign)}
            >
              They Make Your City Feel Like Home
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.storiesSection}>
            <Grid xs={12} md={6} className={classes.stories}>
              <img
                className={classes.storiesImage}
                src="/images/stories/wines_square_image.jpg"
                alt=""
              />
              <Typography variant="h6">
                The Wine Shop That Lifts Your Spirits
              </Typography>
            </Grid>
            <span className={classes.spacer} />
            <Grid xs={12} md={6} className={classes.stories}>
              <img
                className={classes.storiesImage}
                src="/images/stories/hunky_dory_square_image.jpg"
                alt=""
              />
              <Typography variant="h6">
                The Bar with the Coolest Pop-Ups and Cutest Cocktails
              </Typography>
            </Grid>
            <br />
          </Grid>
          <Grid xs={12} className={classes.storiesLink}>
            <Typography variant="h6">
              <Link className={classes.link} href="/stories">
                READ THEIR STORIES
              </Link>
            </Typography>
          </Grid>
        </Grid>
        {/* NOTE: temporarily cutting stories carousel on homepage */}
        {/* *** CAROUSEL OF STORIES *** (includes: image, name, owner, description
        (link), pagination arrows) */}
        {/* NOTE: temporarily commenting out the 'featured in' section until we have sponsor logos */}
        {/* <Grid item xs={12} className={classes.featuredSection}>
          <div className={clsx(classes.featuredItem, 'title')}>
            <Typography variant="h6" className={classes.bold}>
              Featured In
            </Typography>
          </div>
          <div className={clsx(classes.featuredItem, 'icons')}>
            <img
              className={classes.icon}
              alt="logo"
              src="/images/logos/save_your_city_1.png"
            />
            <img
              className={classes.icon}
              alt="logo"
              src="/images/logos/save_your_city_1.png"
            />
            <img
              className={classes.icon}
              alt="logo"
              src="/images/logos/save_your_city_1.png"
            />
            <img
              className={classes.icon}
              alt="logo"
              src="/images/logos/save_your_city_1.png"
            />
            <img
              className={classes.icon}
              alt="logo"
              src="/images/logos/save_your_city_1.png"
            />
          </div>
        </Grid> */}
      </Grid>
    </>
  );
};

async function getProps() {
  return {
    props: {
      styles: {
        color: theme.palette.green,
      },
    },
  };
}
export const getServerSideProps = getProps;

export default Index;
